<template>
  <section>
    <div class="col">
      <div class="article-title-container">
        <span v-if="this.$route.params.id === 'welcome'">
          <h2 class="article-title">{{ welcome.title1 }}</h2>
          <h2 class="article-title-welcome">{{ welcome.title2 }}</h2>
        </span>
        <h2 v-else class="article-title">{{ article.title }}</h2>
        <hr/>
      </div>

      <div class="image-container">
        <img :src="article.imageThumb" class="img-fluid article-image"/>
      </div>

      <div class="article-body" v-html="article.textBody"></div>

      <div class="article-buttons">
        <router-link :to="{ name: 'Ministry'}" class="btn btn-default">
          {{ cms.page.fgBackToMinistry }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "MinistryUpdate",
  data() {
    return {
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "ministry_update",
          MinistryUpdates: false
        },
        page: {},
      },
      article: {
        textBody: '',
        date: '',
        id: '',
        image: '0.jpg',
        title: ''
      },
      welcome: {
        title1: 'Welcome from Executive Director,',
        title2: 'Dan Heighway'
      },
    }
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  mounted() {
    this.designStore.setIsLogo(false);
    this.designStore.setDefinitionHeader(true);
    this.setTitlePageFromCms()

    if (this.$route.params.id === 'welcome') {
      const title = this.article.title.split(',')
      this.welcome.title1 = title[0].trim() + ','
      this.welcome.title2 = title[1].trim()
    }
  },
  async created() {
    await this.createdMethodCalls()
  },
  methods: {
    async createdMethodCalls() {
      if (!this.$route.params.id) {
        await this.$router.push({name: 'Ministry'})
      }

      if (this.$route.params.id === 'welcome') {
        this.article = this.cmsStore.getFeaturedArticle
      } else {
        this.article = this.cmsStore.getMinistryUpdateById(this.$route.params.id)
      }

      this.setContentLoaded(false)
      await this.fetchCmsData();
    },
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setTitlePageFromCms(results.fgMinistryUpdates)
              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
    setTitlePageFromCms(page) {
      const pageTitle = page ? page : 'Ministry Updates'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: "",
        img_class: "bg-ministry-update",
        btn_history: false,
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to && to.fullPath === "/ministry-update") {
        this.$router.push({name: 'Ministry'})
      } else if (to && to.fullPath.includes("/ministry-update/")) {
        // Friend clicked one of the 'related articles', so we need to re-trigger the 'created' lifecycle hook
        this.createdMethodCalls()
        this.setTitlePage(this.product.title)
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/scss/abstracts/_color.scss";

.section-stories {
  border: 1px solid red;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-width: 2000px;
  min-height: 600px;
}

.col {
  margin: 0 auto;
  background-color: $white1;
  width: 50%;
  max-width: 800px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;

}

@media screen and (max-width: $lg) {
  .col {
    width: 100%;
    max-width: 600px;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

p {
  color: $blue-darker;
  line-height: 1.9em;
  margin-bottom: 40px;
}

hr {
  display: flex;
  justify-content: center;
  width: 250px;
  height: 10px;
  background-color: $yellow-darker;
  border: none;
  margin-top: 0;
  margin-bottom: 35px;
}

.article-title,
.article-title-welcome,
.article-date {
  text-align: center;
}

.article-body {
  padding: 40px 80px 80px 80px;
}

@media screen and (max-width: $lg) {
  .article-body {
    padding: 40px 80px 20px 80px;
  }
}

@media screen and (max-width: $sm) {
  .article-body {
    padding: 20px 40px 20px 40px;
  }
}

.article-buttons {
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.article-date {
  margin-bottom: 20px;
}

.article-title {
  margin-top: 50px;
  margin-bottom: 20px;
}

.article-title-welcome:last-child {
  margin-top: 0;
  margin-bottom: 20px;
}

:deep p.letter-signature {
  margin-bottom: 0;
}

:deep ul {
  margin-left: 25px;
  margin-right: 25px;
}

:deep ul li {
  line-height: 135%;
}
</style>
